import { jSith } from "./jquery-replacement";
import { S25Util } from "./s25-util";
import { EventFormOccurrenceUtil } from "../modules/s25-event-creation-form/occurrences/event.form.occurrence.util";
import { ProfileUtil } from "../modules/s25-datepattern/profile.util";
import { OccurrenceValidationUtil } from "./occurrence.validation.util";
import { FormModel } from "../pojo/FormI";
import { S25ItemI } from "../pojo/S25ItemI";
import { OccurrenceI } from "../pojo/OccurrenceI";

export class OccurrenceProfileUtil {
    public static getAllOccData(formModel: FormModel, occ: any, realDates: any, mapRsrvId?: boolean) {
        const locationObj: any = {};
        jSith.forEach(formModel.locations, (_, loc) => {
            const objOcc = formModel.getObjOcc(loc, occ, true);
            if (objOcc) {
                locationObj[loc.itemId] = {
                    isIncluded: objOcc.isIncluded,
                    spaceShare: objOcc.spaceShare,
                    spaceInstructions: objOcc.spaceInstructions,
                    spaceAttendance: objOcc.spaceAttendance,
                    layout: S25Util.deepCopy(objOcc.layout),
                };
            }
        });

        const resourceObj: any = {};
        jSith.forEach(formModel.resources, (_, res) => {
            const objOcc = formModel.getObjOcc(res, occ, true);
            if (objOcc) {
                resourceObj[res.itemId] = {
                    isIncluded: objOcc.isIncluded,
                    resourceInstructions: objOcc.isIncluded && objOcc.resourceInstructions,
                    resourceQuantity: objOcc.isIncluded && objOcc.resourceQuantity,
                };
            }
        });

        return {
            rsrvId: mapRsrvId ? occ.rsrvId : undefined,
            start: realDates ? S25Util.date.clone(occ.evStartDt) : S25Util.date.toS25ISOTimeStr(occ.evStartDt),
            end: realDates ? S25Util.date.clone(occ.evEndDt) : S25Util.date.toS25ISOTimeStr(occ.evEndDt),
            comment: occ.comment,
            state: S25Util.deepCopy(occ.state),
            locations: locationObj,
            resources: resourceObj,
        };
    }

    public static getOccCopy(formModel: FormModel, mapRsrvId?: boolean) {
        const occCopy: any[] = [];
        jSith.forEach(formModel.occurrences, (_, occ) => {
            occCopy.push(OccurrenceProfileUtil.getAllOccData(formModel, occ, true, mapRsrvId));
        });
        return occCopy;
    }

    public static hasHeterogeneousTimes(formModel: FormModel) {
        if (formModel.occurrences.length) {
            const startTime = S25Util.date.toS25ISOTimeStr(formModel.occurrences[0].evStartDt);
            const endTime = S25Util.date.toS25ISOTimeStr(formModel.occurrences[0].evEndDt);

            for (let i = 1; i < formModel.occurrences.length; i++) {
                const ithStartTime = S25Util.date.toS25ISOTimeStr(formModel.occurrences[i].evStartDt);
                const ithEndTime = S25Util.date.toS25ISOTimeStr(formModel.occurrences[i].evEndDt);

                if (startTime !== ithStartTime || endTime !== ithEndTime) return true;
            }
        }
        return false;
    }

    public static hasHeterogeneousOccurrences(formModel: FormModel) {
        if (formModel.occurrences.length > 0) {
            const firstOccStr = S25Util.stringify(
                OccurrenceProfileUtil.getAllOccData(formModel, formModel.occurrences[0], false, false),
            );

            for (let i = 1; i < formModel.occurrences.length; i++) {
                const ithOccStr = S25Util.stringify(
                    OccurrenceProfileUtil.getAllOccData(formModel, formModel.occurrences[i], false, false),
                );

                if (firstOccStr !== ithOccStr) return true;
            }
        }
        return false;
    }

    /**
     * maps reservation data from one pattern to another. It moves location/resource reservations to the appropriate occurrence(s) based on the mapping parameter.
     * end result is found on the formModel object
     * @param mapping
     * @param oldDataArr
     * @param formModel the event form object .locations, .resources, .occurrences properties will be updated
     * @param isCopyInit - we want a slightly different mapping behavior for when initializing from a copy the "included" list is not relevant in this case
     */
    public static mapNewOccurrenceDataFromOld(
        mapping: OccurrenceI.Mapping,
        oldDataArr: any[], //is this a formModel also?
        formModel: FormModel,
        isCopyInit: boolean = false,
    ) {
        //oldData is array of result of getAllOccData(formModel, occ, true) on all occ, eg getOccCopy(OLD formModel)
        if (oldDataArr.length && ["one-by-one", "date-by-date"].indexOf(mapping) > -1) {
            const dateMap: any = {};
            if (mapping === "date-by-date") {
                jSith.forEach(oldDataArr, (_, obj) => {
                    dateMap[S25Util.date.toS25ISODateStr(obj.start)] = obj;
                });
            }

            oldDataArr.sort(S25Util.shallowSortDates("start", "end"));
            formModel.occurrences.sort(S25Util.shallowSortDates("evStartDt", "evEndDt"));

            jSith.forEach(formModel.occurrences, (i, occ) => {
                let oldDataElem: any = null;
                if (mapping === "one-by-one") {
                    if (i < oldDataArr.length) {
                        oldDataElem = S25Util.deepCopy(oldDataArr[i]);
                    } else {
                        oldDataElem = S25Util.deepCopy(oldDataArr[0]);
                        oldDataElem.rsrvId = undefined;
                    }
                } else {
                    //date-by-date
                    const occStartDtStr = S25Util.date.toS25ISODateStr(occ.evStartDt);
                    if (dateMap[occStartDtStr]) {
                        //any resulting occurrences that share the same date as an occurrence that existed before will inherit that occurrence's information
                        oldDataElem = S25Util.deepCopy(dateMap[occStartDtStr]);
                    } else {
                        //dates that did not previously exist will inherit the information of the first occurrence
                        oldDataElem = S25Util.deepCopy(oldDataArr[0]);
                        oldDataElem.rsrvId = undefined;
                    }
                }

                S25Util.date.syncDateToTime(occ.evStartDt, oldDataElem.start);
                S25Util.date.syncDateToTime(occ.evEndDt, oldDataElem.end);
                occ.comment = oldDataElem.comment;
                occ.state = S25Util.deepCopy(oldDataElem.state);
                occ.rsrvId = oldDataElem.rsrvId;

                jSith.forEach(formModel.locations, (_, loc) => {
                    const objOcc = formModel.getObjOcc(loc, occ, true);
                    const locObj = oldDataElem.locations[loc.itemId];

                    if (objOcc && locObj) {
                        //need to set whether an occurrence should be listed as 'included'
                        //When we initialize an event via copy we must ignore the original 'included' list.
                        //Every other time we care about it, and it should probably always be true - TW
                        objOcc.isIncluded = !isCopyInit
                            ? locObj.isIncluded
                            : objOcc.occ.locations?.find((object: S25ItemI) => object.itemId === objOcc.item?.itemId);

                        objOcc.spaceShare = locObj.spaceShare;
                        objOcc.spaceInstructions = locObj.spaceInstructions;
                        objOcc.spaceAttendance = locObj.spaceAttendance;
                        objOcc.layout = S25Util.deepCopy(locObj.layout);
                    }
                });

                jSith.forEach(formModel.resources, (_, res) => {
                    const objOcc = formModel.getObjOcc(res, occ, true);
                    const resObj = oldDataElem.resources[res.itemId];

                    if (objOcc && resObj) {
                        objOcc.isIncluded = !isCopyInit
                            ? resObj.isIncluded
                            : objOcc.occ.resources?.find((object: S25ItemI) => object.itemId === objOcc.item?.itemId);

                        objOcc.resourceInstructions = resObj.resourceInstructions;
                        objOcc.resourceQuantity = resObj.resourceQuantity;

                        if (objOcc.resourceInstructions === false) {
                            objOcc.resourceInstructions = "";
                            objOcc.resourceQuantity = 1;
                        }
                    }
                });
            });
        }
    }

    /**
     * generates occurrences from a meeting pattern on the passed in formModel
     * ANG-4425 - Try to preserve reservations for any existing occurrences to avoid needing to go through approvals again.
     *  new data gets added to formModel
     * @param mapping - the way location/resource reservations shoudl be mapped
     * @param formModel - event form model
     * @param newProfileCode - the new meeting pattern
     * @param isSimulation - should
     * @param refreshCalendarMonth - should the dates be refreshed on a click to pick calendar
     */
    public static reRunPattern(
        mapping: OccurrenceI.Mapping,
        formModel: FormModel,
        newProfileCode: string,
        isSimulation?: boolean,
        refreshCalendarMonth?: any,
    ) {
        newProfileCode = newProfileCode || formModel.profileCode;
        if (newProfileCode && ["dnr", "adhoc"].indexOf(newProfileCode) === -1) {
            const occurrences = ProfileUtil.getOccurrences(
                formModel.timeModel,
                ProfileUtil.getProfileModel(
                    null,
                    newProfileCode,
                    ProfileUtil.getDefaultThroughDate(formModel.timeModel),
                    formModel.timeModel.evStartDt,
                ),
            );

            occurrences.forEach((occ, i) => {
                occ.locations = [
                    ...(formModel?.occurrences[i]?.locations?.length
                        ? formModel?.occurrences[i]?.locations
                        : [...formModel?.locations]),
                ];
                occ.resources = [
                    ...(formModel?.occurrences[i]?.resources?.length
                        ? formModel?.occurrences[i]?.resources
                        : [...formModel?.resources]),
                ];
            });

            if (isSimulation) {
                return OccurrenceValidationUtil.validateMinMaxOccurrences(formModel, occurrences);
            }

            formModel.updateProfileCode(newProfileCode);
            const occCopy = OccurrenceProfileUtil.getOccCopy(formModel, true);

            jSith.forEach([...formModel.occurrences], (_, occ) => {
                formModel.removeOccurrence(occ, newProfileCode);
            });

            jSith.forEach(occurrences, (_, occ) => {
                if (OccurrenceValidationUtil.validateMinMaxOccurrences(formModel, [occ])) {
                    formModel.addOccurrence(
                        occ.evStartDt,
                        occ.evEndDt,
                        occ.minutes,
                        false,
                        formModel.profileCode,
                        occ.locations,
                        occ.resources,
                    );
                }
            });

            formModel.clickToPickApiBean.totalCalendarRefresh(refreshCalendarMonth);
            OccurrenceProfileUtil.mapNewOccurrenceDataFromOld(mapping, occCopy, formModel);
        } else if (isSimulation) {
            return true;
        }
    }

    public static shiftDates(
        days: number,
        formModel: FormModel,
        endDateOnly?: boolean,
        isSimulation?: boolean,
        refreshCalendarMonth?: any,
    ) {
        const spansMidnight = EventFormOccurrenceUtil.spansMidnight(formModel.timeModel);
        days = days || 0;

        if (!formModel.profileCode || ["dnr", "adhoc"].indexOf(formModel.profileCode) > -1) {
            const firstDate = formModel.occurrences.length && formModel.occurrences[0].evStartDt;

            if (isSimulation) {
                let occurrences: any[] = [];
                jSith.forEach([...formModel.occurrences], (_, occ) => {
                    if (endDateOnly) {
                        occurrences.push({
                            evStartDt: S25Util.date.clone(occ.evStartDt),
                            evEndDt: S25Util.date.addDays(occ.evEndDt, days),
                            minutes: S25Util.deepCopy(occ.minutes),
                        });
                    } else {
                        if (S25Util.date.equalDate(firstDate, occ.evStartDt)) {
                            occurrences.push({
                                evStartDt: S25Util.date.addDays(occ.evStartDt, days),
                                evEndDt: S25Util.date.addDays(occ.evEndDt, days),
                                minutes: S25Util.deepCopy(occ.minutes),
                            });
                        } else if (S25Util.date.diffDays(formModel.timeModel.evStartDt, occ.evStartDt) > 0) {
                            occurrences.push({
                                evStartDt: S25Util.date.clone(occ.evStartDt),
                                evEndDt: S25Util.date.clone(occ.evEndDt),
                                minutes: S25Util.deepCopy(occ.minutes),
                            });
                        }
                    }
                });

                return OccurrenceValidationUtil.validateMinMaxOccurrences(formModel, occurrences);
            }

            jSith.forEach([...formModel.occurrences], (_, occ) => {
                occ.spansMidnight = spansMidnight;

                if (endDateOnly) {
                    occ.evStartDt = S25Util.date.clone(occ.evStartDt);
                    occ.evEndDt = S25Util.date.addDays(occ.evEndDt, days);
                } else {
                    if (S25Util.date.equalDate(firstDate, occ.evStartDt)) {
                        occ.evStartDt = S25Util.date.addDays(occ.evStartDt, days);
                        occ.evEndDt = S25Util.date.addDays(occ.evEndDt, days);
                    } else if (S25Util.date.diffDays(formModel.timeModel.evStartDt, occ.evStartDt) > 0) {
                        occ.evStartDt = S25Util.date.clone(occ.evStartDt);
                        occ.evEndDt = S25Util.date.clone(occ.evEndDt);
                    } else {
                        formModel.removeOccurrence(occ);
                    }
                }
            });

            jSith.forEach([...formModel.occurrences], (_, occ) => {
                if (!OccurrenceValidationUtil.validateMinMaxOccurrences(formModel, [occ])) {
                    formModel.removeOccurrence(occ);
                }
            });

            formModel.clickToPickApiBean.totalCalendarRefresh(refreshCalendarMonth);
        }
    }

    public static replacePattern(
        formModel: FormModel,
        justConfirm: boolean,
        newProfileCode: string,
    ): Promise<{ mapping: OccurrenceI.Mapping; confirmed: boolean }> {
        if (!justConfirm && newProfileCode === "dnr" && formModel.occurrences && formModel.occurrences.length > 1) {
            //does not repeat is set, so remove all occurrences but the first one
            for (let i = formModel.occurrences.length - 1; i >= 1; i--) {
                formModel.removeOccurrence(formModel.occurrences[i]);
            }
        }

        if (!formModel?.copyEventId && OccurrenceProfileUtil.hasHeterogeneousOccurrences(formModel)) {
            const data: any = {};
            return window.angBridge.$injector
                .get("s25ModalService")
                .modal("event-form-mapping", data)
                .then(() => {
                    data.confirmed &&
                        !justConfirm &&
                        OccurrenceProfileUtil.reRunPattern(data.mapping, formModel, newProfileCode);
                    return data;
                });
        } else {
            !justConfirm && OccurrenceProfileUtil.reRunPattern("one-by-one", formModel, newProfileCode);
            return jSith.when({ mapping: "one-by-one", confirmed: true });
        }
    }

    public static getNewThroughDateOnProfileCode(formModel: FormModel, days: number) {
        let throughDate = ProfileUtil.getProfileCodeThroughDate(formModel.profileCode);

        if (throughDate) {
            throughDate = S25Util.date.addDays(throughDate, days);
            return ProfileUtil.setThroughDateOnProfileCode(throughDate, formModel.profileCode);
        }
        return formModel.profileCode;
    }

    public static updateDatesOnChange(
        formModel: FormModel,
        mapping: OccurrenceI.Mapping,
        days: number,
        endDateOnly: boolean,
        isSimulation: boolean,
        simulatedDate?: Date,
    ) {
        let resp = true;
        const origStartDt = formModel.timeModel.evStartDt,
            origEndDt = formModel.timeModel.evEndDt;

        if (isSimulation && simulatedDate) {
            if (endDateOnly) {
                formModel.timeModel.evEndDt = simulatedDate;
            } else {
                formModel.timeModel.evStartDt = simulatedDate;
                formModel.timeModel.evEndDt = S25Util.date.addMinutes(
                    formModel.timeModel.evStartDt,
                    S25Util.date.diffMinutes(origStartDt, origEndDt),
                );
            }
        }

        if (formModel.occurrences.length === 0) {
            if (!isSimulation) {
                formModel.addOccurrence(
                    formModel.timeModel.evStartDt,
                    formModel.timeModel.evEndDt,
                    formModel.timeModel.minutes,
                    false,
                    "dnr",
                    [],
                    [],
                );
                formModel.clickToPickApiBean.totalCalendarRefresh(true);
            }
        } else if (
            formModel.occurrences.length === 1 ||
            ["dnr", "adhoc"].indexOf(formModel.profileCode) > -1 ||
            !formModel.profileCode
        ) {
            formModel.profileCode = formModel.occurrences.length === 1 ? "dnr" : "adhoc";
            resp = OccurrenceProfileUtil.shiftDates(days, formModel, endDateOnly, isSimulation, true);
        } else {
            const newProfileCode = endDateOnly
                ? formModel.profileCode
                : OccurrenceProfileUtil.getNewThroughDateOnProfileCode(formModel, days);
            resp = OccurrenceProfileUtil.reRunPattern(mapping, formModel, newProfileCode, isSimulation, true); //mapping set by guaranteed-earlier-call to dateUpdateValidate
        }

        formModel.timeModel.evStartDt = origStartDt;
        formModel.timeModel.evEndDt = origEndDt;

        if (isSimulation) {
            return resp;
        } else {
            formModel.refreshAvailability();
        }
    }
}
