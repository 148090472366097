//@author: devin
import { Injectable } from "@angular/core";

declare global {
    interface Window {
        jSithTimeouts: any;
        angBridge: any;
        [key: string]: any;
    }

    interface HTMLElement {
        [key: string]: any;
    }
}

window.jSithTimeouts = window.jSithTimeouts || [];

export class Defer {
    private static defers: Defer[] = [];

    public promise: Promise<any> = null;
    public resolveFn: Function = null;
    public rejectFn: Function = null;

    public resolved: boolean = false;
    public rejected: boolean = false;
    public done: boolean = false;

    public static flushAll() {
        Defer.defers.forEach(function (d: Defer) {
            if (!d.done) {
                d.resolve();
            }
        });
    }

    public resolve(arg1?: any): any {
        this.resolved = true;
        this.done = true;
        if (this.resolveFn) {
            return this.resolveFn(arg1);
        }
    }

    public reject(arg1?: any): any {
        this.rejected = true;
        this.done = true;
        if (this.rejectFn) {
            return this.rejectFn(arg1);
        }
    }

    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.resolveFn = resolve;
            this.rejectFn = reject;
        });
        Defer.defers.push(this);
    }
}

@Injectable()
export class jSith {
    constructor() {}

    public static DEFAULT_PX_STYLE: any = {
        top: true,
        left: true,
        height: true,
        width: true,
        bottom: true,
        right: true,
        "min-width": true,
        "min-height": true,
        "max-height": true,
        "max-width": true,
    };

    public static noop() {}

    public static isUndefined(obj: any): boolean {
        return typeof obj === "undefined" || obj === null || Number.isNaN(obj);
    }

    public static isDefined(obj: any): boolean {
        return !jSith.isUndefined(obj);
    }

    public static coalesce(...args: any[]): any {
        if (args.length === 0) {
            return;
        } else if (args.length === 1) {
            return args[0];
        } else {
            for (var i = 0; i < args.length; i++) {
                if (jSith.isDefined(args[i])) {
                    return args[i];
                }
            }
        }
    }

    public static toStr(str: string | number): string {
        return jSith.coalesce(str, "") + "";
    }

    public static parseInt(str: string | number, metric?: number): number {
        if (typeof str === "number") {
            return Math.floor(str);
        }
        str = jSith.toStr(str);
        return typeof Number.parseInt === "function" ? Number.parseInt(str, metric) : parseInt(str, metric);
    }

    public static parseFloat(str: string | number, metric?: number): number {
        str = jSith.toStr(str);
        return typeof Number.parseFloat === "function" ? Number.parseFloat(str) : this.parseFloat(str, metric);
    }

    public static isInt(n: any): boolean {
        return !isNaN(jSith.parseInt(n, 10));
    }

    public static timeout(fn: Function, ms?: number): Promise<any> {
        ms = jSith.coalesce(ms, 250);
        let defer = jSith.defer();

        let tFunc: any = () => {
            if (!tFunc.timeoutDone) {
                tFunc.timeoutDone = true;
                try {
                    let resp = fn();
                    if (resp && resp.then) {
                        resp.then(
                            function (results: any) {
                                defer.resolve(results);
                            },
                            function (err: any) {
                                defer.reject(err);
                            },
                        );
                    } else {
                        defer.resolve(resp);
                    }
                } catch (err) {
                    defer.reject(err);
                }
                clearTimeout(tFunc.timeout);
            }
        };

        window.jSithTimeouts.push(tFunc);
        tFunc.timeout = setTimeout(tFunc, ms);
        return defer.promise;
    }

    public static defer() {
        return new Defer();
    }

    public static when(arg1?: any) {
        let defer: Defer = new Defer();
        defer.resolve(arg1);
        return defer.promise;
    }

    public static resolve(arg1: any) {
        return jSith.timeout(function () {
            return arg1;
        }, 1);
    }

    public static reject(arg1: any) {
        return jSith.timeout(function () {
            throw arg1;
        }, 1);
    }

    public static flushAll() {
        window.jSithTimeouts.forEach(function (t: any) {
            if (!t.timeoutDone) {
                t();
            }
        });

        Defer.flushAll();
    }

    public static getEl(el: any): any {
        if (jSith.isNode(el)) {
            return [el];
        }

        if (el.length) {
            let results: any[] = [];
            jSith.forEach(el, function (key: any, e: any) {
                if (jSith.isNode(e)) {
                    results.push(e);
                }
            });
            return results;
        }
    }

    //https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
    public static mobileCheck() {
        let check = false;
        (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    a,
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4),
                )
            )
                check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    }

    public static isNodeList(obj: any) {
        return NodeList.prototype.isPrototypeOf(obj);
    }

    public static isNode(obj: any) {
        return Node.prototype.isPrototypeOf(obj);
    }

    public static isArray(obj: any) {
        return Array.isArray(obj);
    }

    public static parseHTML(str: string): Element {
        let tmp: Document = document.implementation.createHTMLDocument();
        tmp.body.innerHTML = str;
        if (tmp.body.children && tmp.body.children.length) {
            return tmp.body.children[0];
        }
        return null;
    }

    public static removeAttr(el: any, attrName: string): void {
        el = jSith.singleElement(el);
        if (el && attrName) {
            el.removeAttribute(attrName);
        }
    }

    public static addAttr(el: any, attrName: string, attrValue: any): void {
        el = jSith.singleElement(el);
        if (el && attrName) {
            el.setAttribute(attrName, attrValue);
        }
    }

    public static removeClass(el: any, className: string): void {
        el = jSith.singleElement(el);
        if (el && className) {
            el.classList.remove(className);
        }
    }

    public static addClass(el: any, className: string): void {
        el = jSith.singleElement(el);
        if (el && className) {
            let classes = className.split(" ");
            for (let i = 0; i < classes.length; i++) {
                el.classList.add(classes[i]);
            }
        }
    }

    public static focusable(relativeElement: any, i: number, primitiveOnly?: boolean, inputOnly?: boolean) {
        let selector = "input, textarea, select, .ngDropdownPaginated, iframe.tox-edit-area__iframe";
        if (!inputOnly) {
            selector += ", button, [href]";
        }
        if (!primitiveOnly) {
            selector += ', [tabindex]:not([tabindex="-1"])';
        }
        let focusableElements = jSith.find(relativeElement, selector);
        if (focusableElements && focusableElements.length > i) {
            return focusableElements[i];
        } else {
            return null;
        }
    }

    public static element(selector: any, single?: boolean): any {
        let el: any = null;
        if (selector === document || selector === window) {
            el = [selector];
        } else if (selector && selector.length && typeof selector !== "string") {
            el = jSith.getEl(selector);
        } else if (jSith.isNode(selector)) {
            el = [selector];
        } else if (typeof selector === "string") {
            if (selector.startsWith("<") && selector.endsWith(">")) {
                el = jSith.parseHTML(selector);
                return jSith.element(el, single);
            } else {
                el = document.querySelectorAll(selector);
            }
        } else {
            return null;
        }

        if (el && el.length) {
            return single ? el[0] : el;
        } else {
            return null;
        }
    }

    public static singleElement(selector: any): any {
        return jSith.element(selector, true);
    }

    public static find(relativeEl: any, selector: any) {
        if (relativeEl) {
            relativeEl = jSith.singleElement(relativeEl);
            if (relativeEl) {
                return jSith.element(relativeEl.querySelectorAll(selector));
            }
            return null;
        } else {
            return jSith.element(selector);
        }
    }

    public static findSingle(relativeEl: any, selector: any) {
        return jSith.singleElement(jSith.find(relativeEl, selector));
    }

    public static append(targetEl: any, childEl: any): void {
        if (targetEl && childEl) {
            targetEl = jSith.singleElement(targetEl);
            childEl = jSith.singleElement(childEl);

            if (targetEl && childEl) {
                if (targetEl.append) {
                    targetEl.append(childEl);
                } else {
                    targetEl.appendChild(childEl);
                }
            }
        }
    }

    public static remove(el: any): void {
        if (el) {
            el = jSith.singleElement(el);
            if (el.remove) {
                el.remove();
            } else if (el.removeChild && el.parentNode && el.parentNode.removeChild) {
                el.parentNode.removeChild(el);
            }
        }
    }
    public static eventHandlerMap: any = {};

    public static on(el: any, eventName: string, eventHandler: Function, stopOverRide?: boolean) {
        el = jSith.singleElement(el);
        if (el) {
            let events = eventName.split(" ");
            jSith.forEach(events, (_: any, evtAndNS: string) => {
                let evtName = evtAndNS.split(".").shift();
                //Default to addEventListner - Only should NOT do the add IFF - already exists in the map and stopOverRide
                if (!(stopOverRide && jSith.eventHandlerMap[evtAndNS])) {
                    jSith.eventHandlerMap[evtAndNS] = eventHandler;
                    el.addEventListener(evtName, eventHandler);
                }
            });
        }
    }

    public static off(el: any, eventName: string, eventHandler?: Function) {
        el = jSith.singleElement(el);
        if (el) {
            let events = eventName.split(" ");
            jSith.forEach(events, (_: any, evtAndNS: string) => {
                let evtName = evtAndNS.split(".").shift();
                eventHandler = eventHandler || jSith.eventHandlerMap[evtAndNS];
                el.removeEventListener(evtName, eventHandler);
            });
        }
    }

    public static offset(el: any) {
        el = jSith.singleElement(el);
        if (!el || el === document || el === window) {
            return { top: 0, left: 0 };
        }

        let rect = typeof el.getBoundingClientRect === "function" ? el.getBoundingClientRect() : { top: 0, left: 0 };
        return {
            top: rect.top + window.pageYOffset - document.documentElement.clientTop,
            left: rect.left + window.pageXOffset - document.documentElement.clientLeft,
        };
    }

    public static position(el: any) {
        el = jSith.singleElement(el);
        return {
            left: el.offsetLeft,
            top: el.offsetTop,
        };
    }

    public static css(el: any, ruleName: any) {
        el = jSith.singleElement(el);
        return el && window.getComputedStyle(el)[ruleName];
    }

    public static setCss(el: any, ruleMap: any) {
        let elh: HTMLElement = jSith.singleElement(el);
        jSith.forEach(ruleMap, function (key: any, value: any) {
            if (jSith.DEFAULT_PX_STYLE[key] && typeof value === "number") {
                value = value + "px";
            }
            elh.style[key] = value;
        });
    }

    public static getDimension(el: any, dim: any, outer?: boolean): number {
        el = jSith.singleElement(el);
        if (el === document) {
            return Math.max(
                document.body["scroll" + dim],
                document.documentElement["scroll" + dim],
                document.body["offset" + dim],
                document.documentElement["offset" + dim],
                document.documentElement["client" + dim],
            );
        } else if (el === window) {
            return window[(outer ? "outer" : "inner") + dim];
        } else if (outer) {
            return (el && el["offset" + dim]) || 0;
        } else {
            let val: any = jSith.css(el, dim.toLowerCase());
            return (val && parseFloat(jSith.toStr(val).replace("px", ""))) || 0;
        }
    }

    public static height(el: any): number {
        return jSith.getDimension(el, "Height");
    }

    public static width(el: any): number {
        return jSith.getDimension(el, "Width");
    }

    public static outerHeight(el: any): number {
        return jSith.getDimension(el, "Height", true);
    }

    public static outerWidth(el: any): number {
        return jSith.getDimension(el, "Width", true);
    }

    public static forEach(
        list: any,
        fn: (key?: any, value?: any) => any,
        reverse?: boolean,
        shortCircuit?: boolean,
        mutableArr?: boolean,
    ) {
        let resp: any = null;
        if (!list) {
            return;
        }

        let keyIsIter = list.length >= 0;
        if (Array.isArray(list) || keyIsIter) {
            let len: number = list.length;

            if (!reverse) {
                for (let i = 0; i < len; i++) {
                    resp = fn(i, list[i]);
                    len = mutableArr ? list.length : len;
                    if (shortCircuit && resp) {
                        return resp;
                    }
                }
            } else {
                for (let i = len - 1; i >= 0; i--) {
                    resp = fn(i, list[i]);
                    len = mutableArr ? list.length : len;
                    if (shortCircuit && resp) {
                        return resp;
                    }
                }
            }
        } else {
            for (let key in list) {
                if (list.hasOwnProperty(key)) {
                    resp = fn(key, list[key]);
                    if (shortCircuit && resp) {
                        return resp;
                    }
                }
            }
        }
    }

    public static forEachObj(
        list: any,
        fn: (value?: any) => any,
        reverse?: boolean,
        shortCircuit?: boolean,
        mutableArr?: boolean,
    ) {
        jSith.forEach(
            list,
            (_: any, value: any) => {
                fn(value);
            },
            reverse,
            shortCircuit,
            mutableArr,
        );
    }

    public static not(elList: any, el: any) {
        elList = jSith.element(elList);
        el = jSith.singleElement(el);
        if (elList && el) {
            let newList: any[] = [];
            jSith.forEach(elList, function (i: any, listEl: any) {
                if (listEl !== el) {
                    newList.push(listEl);
                }
            });
            return newList;
        }
        return null;
    }
}
