import { ResourceSpaceAvailService } from "../services/resource.space.avail.service";
import { S25Util } from "./s25-util";
import { AvailCheck } from "../pojo/AvailabilityCheck";
import SpecificOcc = AvailCheck.SpecificOcc;
import CheckedItem = AvailCheck.CheckedItem;
import DateMapKey = AvailCheck.DateMapKey;

export class ObjectSearchAvailabilityUtil {
    public static async checkAvailability(
        contextEventId: number,
        contextProfileId: number,
        reservations: AvailCheck.Reservation[],
        accumItems: CheckedItem[],
        perItemF: Function,
        specificOccs?: SpecificOcc[],
        inclQuotas?: boolean,
    ) {
        await ResourceSpaceAvailService.checkAvailability(contextEventId, contextProfileId, reservations);

        for (let item of accumItems) {
            ObjectSearchAvailabilityUtil.decorateReservedItems(item, reservations, specificOccs);
            for (let rsrv of reservations) {
                item.itemTypeId === 4 &&
                    ObjectSearchAvailabilityUtil.setLocationConflicts(
                        item,
                        rsrv.space_reservation,
                        rsrv.reservation_start_dt,
                    );

                item.itemTypeId === 6 &&
                    ObjectSearchAvailabilityUtil.setResourceConflicts(
                        item,
                        rsrv.resource_reservation,
                        rsrv.reservation_start_dt,
                    );
            }

            if (item.itemTypeId === 6) {
                ObjectSearchAvailabilityUtil.processResourceAvailability(item, reservations);
            }

            item?.checkedObj?.conflicts?.sort(S25Util.shallowSortDates("candidateDate", "conflictStart"));
            perItemF && perItemF(item);
        }

        return reservations;
    }

    /**
     * Decorates candidate items with conflicts and availability information
     * @param item -- Candidate item to decorate
     * @param reservations -- Full reservation list
     * @param occurrences -- optional list of specific occurrences to check against
     */
    public static decorateReservedItems(
        item: CheckedItem,
        reservations: AvailCheck.Reservation[],
        occurrences?: SpecificOcc[],
    ) {
        if (occurrences?.length) {
            //ANG-5695 When the first time an object availability is checked is for a specific list need to initialize checkedObj
            if (!item.checkedObj)
                item.checkedObj = {
                    conflicts: [],
                    hasConflicts: false,
                    hasRealConflicts: false,
                    totalDatesChecked: occurrences.length,
                    availableDates: occurrences.length,
                    conflictingDates: 0,
                    availableDatesWithOverrides: occurrences.length,
                };

            for (let occ of occurrences) {
                let someReal = false;
                let len = item.checkedObj?.conflicts?.length || 0;
                for (let i = len - 1; i >= 0; i--) {
                    let conflict = item.checkedObj.conflicts[i];
                    if (conflict.occUUID === occ.uuid) {
                        someReal = someReal || conflict.isReal;
                        item.checkedObj.conflicts.splice(i, 1);
                    }
                }

                if (len > item.checkedObj.conflicts.length) {
                    if (someReal) {
                        item.checkedObj.availableDatesWithOverrides += 1;
                    }
                    item.checkedObj.availableDates += 1;
                    item.checkedObj.conflictingDates -= 1;
                }

                item.checkedObj.hasConflicts = item.checkedObj.conflicts.length > 0;
                item.checkedObj.hasRealConflicts =
                    item.checkedObj.conflicts.filter(function (conflict) {
                        return conflict.isReal;
                    }).length > 0;
            }
        } else {
            item.checkedObj = {
                conflicts: [],
                hasConflicts: false,
                hasRealConflicts: false,
                totalDatesChecked: reservations.length,
                availableDates: reservations.length,
                conflictingDates: 0,
                availableDatesWithOverrides: reservations.length,
            };

            item.apRestriction = undefined;
        }
    }

    public static setLocationConflicts(item: CheckedItem, reservations: AvailCheck.SpaceReservation[], startDt: Date) {
        reservations.forEach((rsrv) => {
            //ANG-4669 flag any low AP reservations
            if (
                item.itemId === S25Util.parseInt(rsrv.space_id) &&
                rsrv.apNoRequest &&
                S25Util.date.equal(S25Util.date.dropTZString(rsrv.apViolationDate), S25Util.date.dropTZString(startDt))
            ) {
                item.apRestriction = {
                    startDt: rsrv.apViolationDate,
                    apNoRequest: true,
                    reason: rsrv.apReason,
                    occUUIDs: rsrv.apViolationUUIDs,
                };
            }

            if (item.itemId === S25Util.parseInt(rsrv.space_id) && rsrv.conflicts && rsrv.conflicts.length) {
                ObjectSearchAvailabilityUtil.setConflicts(item, rsrv);
            }
        });
    }

    public static setResourceConflicts(
        item: CheckedItem,
        reservations: AvailCheck.ResourceReservation[],
        startDt: Date,
    ) {
        reservations.forEach((rsrv) => {
            //ANG-4669 flag any low AP reservations
            if (
                item.itemId === S25Util.parseInt(rsrv.resource_id) &&
                rsrv.apNoRequest &&
                S25Util.date.equal(S25Util.date.dropTZString(rsrv.apViolationDate), S25Util.date.dropTZString(startDt))
            ) {
                item.apRestriction = {
                    startDt: rsrv.apViolationDate,
                    apNoRequest: true,
                    reason: rsrv.apReason,
                    occUUIDs: rsrv.apViolationUUIDs,
                };
            }

            if (item.itemId === S25Util.parseInt(rsrv.resource_id) && rsrv.conflicts && rsrv.conflicts.length) {
                ObjectSearchAvailabilityUtil.setConflicts(item, rsrv);
            }
        });
    }

    /**
     * decorates resource parameter with stock based availability information
     * @param resource - will be changed -
     * @param reservations - will not be changed - list of reservations to check against
     */
    public static processResourceAvailability(
        resource: CheckedItem,
        reservations: AvailCheck.Reservation[] & Partial<{ allResAvailDateMap: AvailCheck.ResAvailDateMap }>,
    ) {
        resource.allResAvailDateMap = resource.allResAvailDateMap || {};
        S25Util.extend(resource.allResAvailDateMap, reservations.allResAvailDateMap);

        const unlimited = Math.pow(2, 31);
        let minAvailable = unlimited,
            maxAvailable = -1,
            stockTotal = resource.stock_level || -1;

        reservations.forEach((rsrv) => {
            let available;
            let key: DateMapKey =
                resource.itemId +
                "&" +
                S25Util.date.toS25ISODateTimeStr(S25Util.date.parseDropTZ(rsrv.reservation_start_dt));
            let resAvailDateObj = resource.allResAvailDateMap[key];
            if (resAvailDateObj) {
                available = resAvailDateObj.stockLevel;
                stockTotal = resAvailDateObj.stockTotal;
            }

            available = Math.max(S25Util.coalesce(available, unlimited), 0);

            if (available > maxAvailable) {
                maxAvailable = available;
            }

            if (available < minAvailable) {
                minAvailable = available;
            }

            if (available > stockTotal) {
                stockTotal = available;
            }
        });

        resource.quantity = stockTotal > -1 && stockTotal < unlimited ? stockTotal : "Unlimited";
        resource.minAvailable = minAvailable;
        resource.maxAvailable = maxAvailable;
        resource.availability = "";

        if (resource.maxAvailable === 0) {
            //if no resource total available, then available dates should be 0, causing "Unavailable" to show for action
            resource.checkedObj.availableDatesWithOverrides = 0;
            resource.checkedObj.availableDates = 0;
        }

        if (resource.quantity === "Unlimited") {
            resource.availability = "Unlimited";
        } else {
            if (resource.minAvailable === resource.maxAvailable) {
                resource.availability = resource.minAvailable + " / " + resource.quantity;
            } else {
                resource.availability =
                    resource.minAvailable + " to " + resource.maxAvailable + " / " + resource.quantity;
            }
        }
    }

    /**
     * Adds conflicts to a candidate item's checked object
     * @param item
     * @param rsrv
     */
    public static setConflicts(item: CheckedItem, rsrv: AvailCheck.SpaceReservation | AvailCheck.ResourceReservation) {
        item.checkedObj.availableDatesWithOverrides -= rsrv.hasRealConflicts ? 1 : 0;
        item.checkedObj.availableDates -= 1;
        item.checkedObj.conflictingDates += 1;
        item.checkedObj.conflicts = item.checkedObj.conflicts.concat(rsrv.conflicts);
        item.checkedObj.hasConflicts = item.checkedObj.hasConflicts || rsrv.hasConflicts;
        item.checkedObj.hasRealConflicts = item.checkedObj.hasRealConflicts || rsrv.hasRealConflicts;
    }
}
